export function isDescendant(parent: string, child?: EventTarget | null) {
  if (!child) return false;

  // @ts-ignore
  var node: HTMLElement = child.parentNode;
  while (node != null && node.tagName !== "document") {
    if (node.className?.split(" ").includes(parent)) {
      return true;
    }
    // @ts-ignore
    node = node.parentNode;
  }
  return false;
}

export function isDescendantOfEl(parent: Node, child: Node): boolean {
  let node: Node | null = child.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}

export function getScrollParent(node) {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight && node.offsetHeight) {
    return node;
  } else {
    return !!node.parentNode ? getScrollParent(node.parentNode) : null;
  }
}
